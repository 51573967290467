import Profile from './Profile.js';
import Experience from './Experience.js';


const Home = () => {
    //? Web Title here!!
    return (
    <>
		<div class="main-wrapper">
			 {/*  Profile  */}
			<Profile />
			 {/* Experience Section  */}
			<Experience />
		</div>
    </>
    )
}

export default Home;
// import { useState } from "react";
import { Link } from "react-router-dom";

const Navigation = () => {

    return (
        <>
        <header>
            <nav>
                <p className="logo">LISAN<span className="blink">_</span></p>
                
                <div id="slidebar">
                    <div>
                    <p className="logo">LISAN<span className="blink">_</span>
                    
                    </p>
                    <p id="close" onClick={() => {
                    const slide = document.getElementById('slidebar');

                    slide.style.width = '0%'
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                        </svg>
                    </p>
                    </div>
                    <div id="slide-link">
                        <p><Link href="">Github</Link></p>
                        <p><Link>MyStore</Link></p>
                    </div>
                </div>
                <div id="nav-link">
                    <Link href="/">Home</Link>
                </div>
                <p id="menu" onClick={() => {
                    const slide = document.getElementById('slidebar');

                    slide.style.width = '100%';
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                    </svg>
                </p>
            </nav>
        </header>
        </>
    )
}

export default Navigation;